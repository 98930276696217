import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant'
import ElementUI from 'element-ui'
import { httpGet, httpPost } from '@/assets/js/Request'
import { showToast } from '@/assets/js/Utils'
import 'vant/lib/index.css'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/global.less'

Vue.prototype.$get = httpGet;
Vue.prototype.$post = httpPost;
Vue.prototype.$showToast = showToast;

Vue.config.productionTip = false
Vue.use(vant);
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
