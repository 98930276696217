import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Store from '@/store'
import EventBus from '@/assets/js/EventBus'
import { initWebSocket } from "@/assets/js/Socket"
import { Toast } from 'vant'
import { getUserOpenId } from '@/assets/js/Authorize'
import { isWeChat } from '@/assets/js/Utils'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // 看效果：name 以 SG 开头
  {
    path: '/',
    name: 'SG-Index',
    component: () => import('../views/ShoppingGuide/Index/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/detail/:pid',
    name: 'SG-Detail',
    component: () => import('../views/ShoppingGuide/Detail/index.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/search',
    name: 'SG-Search',
    component: () => import('../views/ShoppingGuide/Search/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/collect',
    name: 'SG-Collect',
    component: () => import('../views/ShoppingGuide/Collect/index.vue'),
    meta: {
      keepAlive: false
    }
  },
  // 后台管理
  {
    path: '/shoppingGuide/admin/login',
    name: 'SG-AdminLogin',
    component: () => import('../views/ShoppingGuide/Admin/login.vue'),
    // meta: {
    //   keepAlive: true
    // }
  },
  {
    path: '/shoppingGuide/admin/index',
    name: 'SG-AdminIndex',
    component: () => import('../views/ShoppingGuide/Admin/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/preview/:pid',
    name: 'SG-Preview',
    component: () => import('../views/ShoppingGuide/Detail/index.vue'),
    meta: {
      keepAlive: false
    }
  },
  // 算价格：name 以 CV 开头
  {
    path: '/computeValue/home',
    name: 'CV-Home',
    component: () => import('../views/ComputeValue/main.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/computeValue/orderDetail/:id',
    name: 'CV-OrderDetail',
    component: () => import('../views/ComputeValue/Order/orderDetail.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/computeValue/render3D/:id',
    name: 'CV-Render3D',
    component: () => import('../views/ComputeValue/render3D.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/computeValue/*',
    redirect: "/computeValue/home",
  },
  // 找工厂：name 以 FF 开头
  {
    path: '/findFactory/index',
    name: 'FF-Index',
    component: () => import('../views/FindFactory/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/findFactory/personCenter',
    name: 'FF-PersonCenter',
    component: () => import('../views/FindFactory/pages/personCenter.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/findFactory/login',
    name: 'FF-Login',
    component: () => import('../views/FindFactory/pages/login.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/findFactory/*',
    redirect: '/findFactory/index',
  },
  // 色卡
  {
    path: '/colorCards/:id',
    name: 'ColorCards',
    component: () => import('../views/Common/colorCards.vue'),
  },
  // API
  {
    path: '/api/getUserInfo',
    name: 'GetUserInfo',
    component: () => import("../views/Common/getUserInfo.vue"),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/api/ossUploader',
    name: 'OssUploader',
    component: () => import("../views/Common/ossUploader.vue"),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '*',
    redirect: "/"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 页面缓存列表，路由 name 需要与页面组件名一致，才能应用缓存；
const keepAlivePages = Array.from(routes.filter((route: RouteConfig) => {
  return route.meta && route.meta.keepAlive;
}), (route) => route.name?.replace("-", ""))
Store.commit("initKeepAlivePages", keepAlivePages);



router.beforeEach((to, from, next) => {

  if(to.path.startsWith("/SG-AdminIndex")){
    if(Store.state.token){
      next()
    }else{
      next({
        path: "/SG-AdminLogin",
        query:{
          backto:to.fullPath
        }
      })
    }
  }else{
    next()
  }


  if (/computeValue/i.test(to.path)) {
    document.title = "算价格";
    localStorage.setItem("defaultRedirect", "/computeValue/home");
  } else if (/findFactory/i.test(to.path)) {
    document.title = "找工厂";
    localStorage.setItem("defaultRedirect", "/findFactory/index");
  } else {
    document.title = "柜柜云导购";
    localStorage.setItem("defaultRedirect", "/");

    switch (to.name) {
      case "SG-Detail":
        // ts 不能直接调用 hasOwnProperty 方法
        if (Object.prototype.hasOwnProperty.call(to.query, 'uid')) {
          Store.commit("setSocketId", to.query.uid);
          next({
            name: to.name,
            params: {
              pid: to.params.pid,
            }
          });
          return;
        }
        break;

      case "SG-Index":
        if (Object.prototype.hasOwnProperty.call(to.query, 'uid')) {
          // add_shopping_guide_times
          Store.commit("setSocketId", to.query.uid);
          if (Object.prototype.hasOwnProperty.call(to.query, 'platform')) {
            Store.commit("setControl", to.query.platform);
          }
          next({
            name: to.name
          });
          return;
        }
        break;

      default:
        break;
    }

    const socketId = sessionStorage.getItem("socketId");
    if (socketId) {
      Vue.prototype.$socket = initWebSocket(socketId);
    }
  }

  // 获取 openid 并跳转
  if (Store.state.entrancePath == "") {
    Store.commit("setEntrancePath", to.path);

    if (isWeChat() && Store.state.openId == "") {
      getUserOpenId("https://guide.eggrj.com/api/getUserInfo");
    }
  }

  next();
})

export default router;

// 页面跳转同步
EventBus.$on("toGuideSearch", () => {
  router.replace({ name: "SG-Search" }).catch((err) => err);
});

EventBus.$on("toGuideCollect", () => {
  router.replace({ name: "SG-Collect" }).catch((err) => err);
});

EventBus.$on("toGuideIndex", () => {
  router.replace({ name: "SG-Index" }).catch((err) => err);
});

EventBus.$on("toGuideDetail", (id: string) => {
  router.push({
    name: "SG-Detail",
    params: { pid: id },
  }).catch((err) => err);
});

EventBus.$on("goBackTop", (updated: boolean) => {
  // if (updated) Store.commit("changeUpdateTag", true);
  Toast.clear();
  router.back();
});
