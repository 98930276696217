import EventBus from "./EventBus";

export class GWebSocket {
    private host = '';
    private socket: null | WebSocket = null;
    private listeners: { [x: string]: any } = {};
    private online = 0;
    private times = 5; // 重连次数
    private timer: null | number = null;
    private heartbeat: null | number = null;
    private interval = 30000;

    public constructor(host: string) {
        this.host = host;
        this.createWebSocket();

        window.onoffline = () => {
            this.closeWebSocket();
            window.ononline = () => {
                this.rebuildWebSocket();
            }
        }
    }

    private getOnlineCount(): void {
        if (!this.socket) return;
        if (!this.checkWebSocket()) {
            return;
        }
        if (this.heartbeat) {
            clearTimeout(this.heartbeat);
        }
        this.socket.send("online");
        this.heartbeat = window.setTimeout(() => {
            this.getOnlineCount();
        }, this.interval);
    }

    private createWebSocket(): void {
        this.socket = new WebSocket(this.host);
        this.setEventHandler();

        this.socket.addEventListener('open', () => {
            console.warn("opened.");
        });

        this.socket.addEventListener('close', () => {
            this.rebuildWebSocket();
            console.warn("closed.");
        });
    }

    private rebuildWebSocket(): void {
        if (this.checkWebSocket() || this.timer || this.times <= 0) {
            return;
        }

        console.log("reconnecting...")

        this.timer = window.setTimeout(() => {
            this.timer = null;
            this.rebuildWebSocket();
        }, 5 * 1000)

        this.times--;
        this.createWebSocket();
        // location.reload();
    }

    private closeWebSocket(): void {
        if (!this.socket) return;
        if (this.checkWebSocket()) {
            this.socket.close();
        }
    }

    private checkWebSocket(): boolean {
        return this.socket !== null && this.socket.readyState == this.socket.OPEN
    }

    public sendMessage(data: any): void {
        if (!this.socket) return;
        if (this.online > 1) {
            if (typeof data === "string") {
                this.socket.send(data);
            } else {
                this.socket.send(JSON.stringify(data));
            }
        }
    }

    public dispatchEvent(type: string, data: string | boolean | number): void {
        if (!this.socket) return;
        if (this.online > 1) {
            this.socket.send(JSON.stringify({
                type,
                data
            }));
        }
    }

    private addEventHandler(key: string, callback: any): void {
        if (callback) {
            this.listeners[key] = callback;
        }
    }

    private setEventHandler(): void {
        if (!this.socket) return;
        this.socket.onmessage = (event: any) => {
            if (/^[0-9]*$/.test(event.data)) {
                this.online = event.data;
                EventBus.$emit("online-change", this.online);
            } else if (event.data == "update") {
                this.getOnlineCount();
            } else {
                try {
                    const data = JSON.parse(event.data);
                    EventBus.$emit(data.type, data.data);
                } catch (e) {
                    console.warn("参数错误：", e);
                }
            }
            // for (const func of Object.values(this.listeners)) {
            //     func(event);
            // }

        }
    }
}

export let GSocket: null | GWebSocket = null;

const host = process.env.NODE_ENV === 'production' ? "wss://beta.eggi.cn/land/websocket-tunnel?uid=" : "ws://localhost:8081?uid=";

export function initWebSocket(uid: string | number) {
    if (!GSocket) {
        console.error("initWebSocket.");
        GSocket = new GWebSocket(host + uid);
    }
    return GSocket;
}