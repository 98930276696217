import { httpPost } from "./Request";
import wx from 'weixin-js-sdk';

const AppId = "wx7999d731eb9704b4";
// 153ccf9e8f6e191ad452e07fd9debd8e

export function getSearchParams(key: string): string {
    const searchString = window.location.search || window.location.href.split("?")[1];
    const searchParams = new URLSearchParams(searchString);
    return searchParams.get(key) || ""
}

export function getUserOpenId(redirect = window.location.href, callback = (res: string): void => { res }): void {
    const code = getSearchParams('code');
    if (code) {
        callback(code);
    } else {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AppId}&redirect_uri=${encodeURIComponent(redirect)}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`
    }
}

export function configWechatAPI(url: string): Promise<any> {
    return httpPost("/big_home/get_wechat_signature", { url }).then((res: any) => {
        wx.config({
            ...res.data.data,
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            jsApiList: ["updateAppMessageShareData", "onMenuShareAppMessage"] // 必填，需要使用的JS接口列表
        });
        return res;
    })
}