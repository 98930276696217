import Vue from 'vue'
import Vuex from 'vuex'
// import { setItem,getItem } from '@/assets/js/storage'
import EventBus from '@/assets/js/EventBus'
Vue.use(Vuex)

const devOpenId = process.env.NODE_ENV === 'development' ? "Dev-openId." : "";

const store = new Vuex.Store({
  state: {
    //保存公共数据
    // tokenInfo:getItem('tokenInfo') || {} ,
    // userInfo:{},
    token:"",
    refreshToken:"",
    openId: localStorage.getItem("openId") || devOpenId,
    entrancePath: localStorage.getItem("entrancePath") || "",
    keepAlivePages: [],
    control: window.sessionStorage.getItem("control") || "",
    socketId: window.sessionStorage.getItem("socketId") || "",
    online: 0,
    userinfo: '', // 云工厂个人信息
    shoppingGuideUserInfo: {}, //云导购后台管理个人信息
    goodsCount: JSON.parse(window.localStorage.getItem("shopCartArr") || "[]").length, // 购物车商品类型数量
    computeValueIndex: Number(window.sessionStorage.getItem("computeValueIndex") || "0"),
    computeValueNavList: [
      {
        id: "home",
        name: "主页",
        icon: "wap-home-o",
        iconActive: "wap-home",
      },
      {
        id: "cart",
        name: "购物车",
        icon: "shopping-cart-o",
        iconActive: "shopping-cart",
      },
      {
        id: "share",
        name: "分享",
        icon: "share-o",
        iconActive: "share",
      },
      {
        id: "consult",
        name: "咨询",
        icon: "phone-o",
        iconActive: "phone",
      },
      {
        id: "order",
        name: "订单",
        icon: "label-o",
        iconActive: "label",
      },
    ],
    sharePhone: window.sessionStorage.getItem("sharePhone") || "",
  },
  getters: {
    // getStorage(state){
    //   if(!state.token){
    //     state.token = JSON.parse(wind.localStorage.getItem("token"));
    //   }
    //   return state.token;
    // },
    curComputeValuePage(state) {
      return state.computeValueNavList[state.computeValueIndex];
    }
  },
  mutations: {
    //存储用户信息
    mSetTokenInfo(state,token){
      // state.userInfo = JSON.parse(userInfo)
      state.token = token
      //调用storage 进行持久化
      localStorage.setItem("token", token);
    },

    mSetRefreshTtoken(state,refreshToken){
      state.refreshToken = refreshToken
      localStorage.setItem("refreshToken", refreshToken);
    },

    //只跟新token 不更新响应拦截器里面的refreshToken
    mUpdateToken(state,newToken){
      state.token = newToken
      localStorage.setItem("token", newToken);
    },
    //跟新refreshToken
    mUpdateRefreshTtoken(state,newRefreshToken){
      state.refreshToken = newRefreshToken
      localStorage.setItem("refreshToken", newRefreshToken);
    },

    //清除token
    $_removeStorage(state,value) {
      localStorage.removeItem("token",)
    },

    setOpenId(state, code) {
      state.openId = code;
      localStorage.setItem("openId", code);
    },
    setEntrancePath(state, path) {
      state.entrancePath = path;
      localStorage.setItem("entrancePath", path);
    },
    initKeepAlivePages(state, list) {
      state.keepAlivePages = list;
    },
    setControl(state, value) {
      state.control = value;
      sessionStorage.setItem("control", value);
      console.warn(state.control);
    },
    setSocketId(state, value) {
      state.socketId = value;
      sessionStorage.setItem("socketId", value);
      console.warn(state.socketId);
    },
    setOnline(state, value) {
      state.online = value;
    },
    updateUserInfo(state, value) {
      state.userinfo = value
    },
    updateGoodsCount(state, count) {
      state.goodsCount = count;
    },
    updateComputeValueIndex(state, index) {
      state.computeValueIndex = index;
    },
    setSharePhone(state, value) {
      state.sharePhone = value;
      window.sessionStorage.setItem("sharePhone", value);
    },
    setShoppingGuideUserInfo(state, userInfo) {
      state.shoppingGuideUserInfo = userInfo;
      window.localStorage.setItem("guideUserInfo", JSON.stringify(userInfo));
    }
  },
  actions: {

  },
  modules: {
  }
})

export default store;

EventBus.$on("online-change", (count: number) => {
  store.commit("setOnline", count);
})
