import Vue from "vue";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import VueAxios from "vue-axios";
import store from "@/store/index";
import router from "@/router";
import calcKeyByRSA from "./CommonFun";
import ElementUI from "element-ui";

Vue.use(VueAxios, axios);

// export const toolsServer = "https://ggtools.thinkerx.com";
export const betaServer = process.env.VUE_APP_GUIDE_ORIGIN;
// export const devServer = "https://dev.eggi.cn";

// VUE_APP_GUIDE_ORIGIN, VUE_APP_ORIGIN 在根目录下 .env 中配置
export const guideServer = process.env.VUE_APP_GUIDE_ORIGIN;
export const baseURL = process.env.VUE_APP_ORIGIN;

const gAxios = Vue.axios.create({
  baseURL,
});

//判断是否需要刷新token
let isRefreshing = false;
//保存需要重新发起请求的队列
let retryRequests: Array<any> = [];

export function httpPost(url: string, data?: any, config?: AxiosRequestConfig) {
  //请求头带token
  if (!config?.headers && !config?.headers?.token) {
    return gAxios.post(url, data, {
      ...config,
      headers: { token: localStorage.token },
    });
  } else {
    return gAxios.post(url, data, config);
  }
}
export function httpGet(
  url: string,
  params?: any,
  config?: AxiosRequestConfig
) {
  if (params) {
    const searchString = new URLSearchParams(Object.entries(params)).toString();
    if (!config?.headers && !config?.headers?.token) {
      return gAxios.get(url + "?" + searchString, {
        ...config,
        headers: { token: localStorage.token },
      });
    }
  }
  //请求头带token
  if (!config?.headers && !config?.headers?.token) {
    return gAxios.get(url, {
      ...config,
      headers: { token: localStorage.token },
    });
  } else {
    return gAxios.get(url, config);
  }
  // return gAxios.get(url, config);
  // return gAxios.get(url + '?' + searchString, config);
}
export function httpsPost(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return httpPost(url, data, config);
}

// 请求拦截器
gAxios.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    return config;
  },
  function (error: AxiosError) {
    // 请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
gAxios.interceptors.response.use(
  function (response: AxiosResponse) {
    // 响应数据
    return response;
  },
  async function (error: AxiosError) {
    // 响应错误
    if (error) {
      console.log(error);
      //如果是401错误,则做以下错误
      if (error.response?.status == 401) {
        // const refreshToken = store.state.refreshToken
        const refreshToken = localStorage.getItem("refreshToken");
        if (!isRefreshing) {
          isRefreshing = true;
          //如果refreshToken过期重新登录
          if (!refreshToken) {
            ElementUI.Message({
              duration: 1500,
              message: "错误",
              type: "warning",
            });

            localStorage.removeItem("token");
            router.push({ name: "SG-AdminLogin" });
            return;
          }

          const RSAKey = calcKeyByRSA.calcKeyByRSA({
            refresh_token: refreshToken,
          });
          return axios({
            url: guideServer + "/shopping_guide/refresh_token",
            method: "POST",
            data: {
              refresh_token: refreshToken,
            },
            headers: {
              sign: RSAKey,
            },
          }).then((res) => {
            if (res.data.status == 1) {
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("refreshToken", res.data.data.refresh_token);
              retryRequests.forEach((cb) => {
                cb(res.data.data.token);
              });
              retryRequests = [];
              (error as any).config.headers["token"] = res.data.data.token;
              return gAxios(error.config);
            } else {
              localStorage.removeItem("token");
              localStorage.removeItem("refreshToken");
              ElementUI.Message({
                duration: 1500,
                message: "登录信息过期，请重新登录",
                type: "warning",
              });
              router.push({ name: "SG-AdminLogin" });
              return;
            }
          });
        } else {
          // 刷新token时，存入队列
          return new Promise((resolve) => {
            retryRequests.push((token: string) => {
              (error as any).config.headers["token"] = token;
              resolve(gAxios(error.config));
            });
          });
        }
      }
    }
    return Promise.reject(error);
  }
);

// if(refreshToken){
//     //用refresh_token 重发请求 再次取回一个有效期
//     try{
//         httpPost(guideServer + "/shopping_guide/refresh_token", data, {
//             //请求头中加验签
//             headers: { sign: calcKeyByRSA.calcKeyByRSA(data) },
//           }).then((res: any) => {
//                 console.log(res)

//                 //赋值新的token
//                     const newToken = res.data.token;
//                     // const newRefreshToken = res.data.refresh_token
//                 //更新vuex
//                     localStorage.setItem("token", newToken);
//                     store.commit('mUpdateToken',newToken);
//                     // store.commit('mUpdateRefreshTtoken',newRefreshToken)

//           })
//           //再发请求
//         //   return gAxios(error.config)

//     }catch{
//         //回到登录页
//         localStorage.clear;
//         router.push({ name: "SG-AdminLogin" });
//     }
// }else{
//     localStorage.clear;
//     router.push({ name: "SG-AdminLogin" });
// }
