import { Toast } from "vant";
import Store from "@/store/index";
import EventBus from "./EventBus";
import { httpPost } from "./Request";

export function showToast(msg = ""): void {
    Toast({
        message: msg || "功能开发中，敬请期待！",
        duration: 2000
    })
}

// 是否微信环境
export function isWeChat(): boolean {
    return /MicroMessenger/i.test(window.navigator.userAgent);
}

export function getRandomString(len = 32) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let string = '';
    for (let i = 0; i < len; i++) {
        string += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return string;
}

export function getTodayDate() {
    const date = new Date(); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    const Y = date.getFullYear();
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    const D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + '';
    const h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
    const m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ''; + '';
    const s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
    return `${Y}-${M}-${D}`;
}

// 上传文件到 OSS
export function uploadFileToOss(file: any, dir: string, isDefault = true): Promise<any> {
    return new Promise((resolve, reject) => {
        httpPost('https://guigui.thinkerx.com/oss/getToken', { dir },{ headers:{} }).then((res: any) => {
            const uploadToken = res.data;
            const form = new FormData();

            let key = "";
            if (isDefault) {
                const filename = process.env.NODE_ENV == "production" ? getRandomString() : ("test-filename" + "_" + String(new Date().getTime()));
                const extension = file.name.split(".")
                key = getTodayDate() + "/" + filename + "." + extension[extension.length - 1];
            } else {
                key = file.name;
            }
            form.append("name", file.name);
            form.append("key", uploadToken.dir + key);
            form.append("OSSAccessKeyId", uploadToken.accessid);
            form.append("signature", uploadToken.signature);
            form.append("policy", uploadToken.policy);
            form.append("file", file.raw);

            httpPost(uploadToken.host, form).then((res: any) => {
                resolve(uploadToken.host + "/" + uploadToken.dir + key);
            }).catch(err => {
                reject(err);
            })
        }).catch(err => {
            reject(err);
        })
    })
}

// 加入购物车
export function addCart(obj: any = {}): void {
    let result: any = []
    const tmpStr: any = localStorage.getItem('shopCartArr')
    const tmpArr: any = JSON.parse(tmpStr)
    if (tmpArr && tmpArr.length !== 0) {
        const i = tmpArr.findIndex((el: any) => el.id === obj.id);
        if (i === -1) { //购物车中没有找到该元素
            obj.count = 1;
            result = tmpArr.concat([obj]);
        } else {
            tmpArr[i] = { ...tmpArr[i], count: tmpArr[i].count + 1 };
            result = tmpArr;
        }
        localStorage.removeItem('shopCartArr')
        localStorage.setItem('shopCartArr', JSON.stringify(result))
    } else {
        // console.log("增加购物车数据");
        result = [{ ...obj, count: 1 }]
        localStorage.setItem('shopCartArr', JSON.stringify(result))
    }
    Store.commit("updateGoodsCount", result.length);
}

// 删除购物车
export function reduceCart(ids: any): void {
    const result: any = [];
    const tmpStr: any = localStorage.getItem('shopCartArr')
    const tmpArr = JSON.parse(tmpStr)
    for (let i = 0; i < tmpArr.length; i++) {
        if (ids.indexOf(tmpArr[i].id) == -1) {
            result.push(tmpArr[i]);
        }
    }
    localStorage.removeItem('shopCartArr')
    localStorage.setItem('shopCartArr', JSON.stringify(result));
    Store.commit("updateGoodsCount", result.length);
    EventBus.$emit("changeManyAdded", ids); // 改变商品在购物车的状态
}

// 判断商品是否已在购物车
export function isInCart(id: number): boolean {
    const tmpArr: any = localStorage.getItem("shopCartArr") || "[]";
    const shoppingCart = JSON.parse(tmpArr);
    if (shoppingCart.find((el: any) => el.id === id)) return true
    return false
}